<template>
  <div>
    <el-dialog title="请选择三维模型类型" width="400px" :modal-append-to-body="true"
               :append-to-body="true" :close-on-click-modal="false" :visible.sync="visible"
               :before-close="handleClose">
      <div class="text_center">
        <el-button :type="selectLevel === 0 ? 'primary' : ''" size="small" @click="modelingLevel(0)">高模</el-button>
        <el-button :type="selectLevel === 1 ? 'primary' : ''" size="small" @click="modelingLevel(1)">中模</el-button>
        <el-button :type="selectLevel === 2 ? 'primary' : ''" size="small" @click="modelingLevel(2)">低模</el-button>
      </div>
      <div style="padding-top: 30px" class="text_center">
        <el-button size="small" @click="handleClose()">取消</el-button>
        <el-button type="primary" size="small" @click="sureSelect()">确定</el-button>
      </div>
    </el-dialog>
    <coll-list ref="collectionList" @seltDataColl="seltDataColl"></coll-list>
  </div>
</template>

<script>
import CollList from "@/views/modules/dyyg/gather/resource/collList.vue";

export default {
  name: "joinResources",
  components: {CollList},
  data() {
    return {
      visible: false,
      selectLevel: '', //三维的高中低模
      resourceName: '', //资源名称

      fileName: '', //第一个文件名称
      type: '', //文件类型
      ids: [], //选择的所有文件
    }
  },

  methods: {
    init(ids, type, fileName) {
      this.ids = ids
      // this.type = type
      this.type = 0
      this.fileName = fileName
      this.nextStep()
    },

    modelingLevel(num) {
      this.selectLevel = num
    },

    nextStep() {
      let that = this
      that.$prompt('请输入资源名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValidator: (value) => {
          if (!value || value.length == 0) {
            return '资源名称不能为空'
          }
          if (value.length > 60) {
            return '资源名称最长为60个字符'
          }
        },
        inputPlaceholder: '请输入资源名称(限60字)',
        inputValue: that.fileName,
        inputErrorMessage: '资源名称不正确'
      }).then(({value}) => {
        this.resourceName = value
        if (this.type == 0) {
          this.visible = true
        } else {
          this.selectColl()
        }
      })
    },

    //三维文件选择高中低模
    sureSelect() {
      if (this.selectLevel !== '') {
        this.selectColl()
      }else {
        this.$message.warning('请选择模型等级')
      }
    },

    //选择藏品资源弹窗
    selectColl() {
      this.$refs.collectionList.int(this.type == 0 ? 1 : 2, this.type == 0 ? '' : 'jump')
    },

    //选好了藏品
    seltDataColl(row) {
      this.handleClose()
      console.log(row)
      // this.$axios(this.api.digital.comprehensiveResourcesFileLinkRemoveByIds, ids, 'post').then(data => {
      //   if (data && data.status) {
      //     this.$message.success(`加入资源库成功`)
      //     this.$emit('refreshList')
      //   } else {
      //     this.$message.error(data.msg)
      //   }
      // })
    },

    handleClose() {
      this.selectLevel = ''
      this.visible = false;
    },
  },
}
</script>

<style scoped>

</style>