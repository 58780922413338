<template>
  <el-dialog title="文件详情" :fullscreen="true" :modal-append-to-body="true"
             :append-to-body="true" :close-on-click-modal="false" :visible.sync="visible"
             :before-close="handleClose">
    <el-row class="detailBox">
      <el-col :span="showList ? 18 : 24" class="infoBox p_r">
        <div :class="['showListBtn', showList ? 'el-icon-arrow-right':'el-icon-arrow-left']"
             @click="showList = !showList"></div>
        <div class="modelBox text_center" v-if="dataList.length == 0">
          <img src="@/assets/img/upload.png" class="m_t2">
          <div class="m_t2">暂无资源信息哦~</div>
        </div>
        <div class="modelBox" v-else v-loading="loading">
          <el-carousel :autoplay="imgAutoplay" indicator-position="none" type="card"
                       height="calc(100vh - 290px)" @change="changeImg"
                       v-if="listType == 1">
            <el-carousel-item v-for="(item, index) in dataList" :key="item.id">
              <el-image :src="item.url" fit="contain" :preview-src-list="[item.url]">
                <el-image slot="placeholder" fit="contain" :src="require('@/assets/img/collPhoto.png')"></el-image>
                <el-image slot="error" fit="contain" :src="require('@/assets/img/collPhoto.png')"></el-image>
              </el-image>
            </el-carousel-item>
          </el-carousel>
          <el-row :gutter="10" class="h100" v-else>
            <el-col :span="showList ? 17 : 23" class="h100">
              <iframe allowfullscreen="true" id="frame" :src="this.$pdfPreviewUrl() + 'onlinePreview?url=' + fileUrl"
                      style="width: 100%;height: 100%;"></iframe>
            </el-col>
            <el-col v-show="showList" :span="6" class="videoList">
              <el-table
                  :data="dataList"
                  size="small"
                  height="100%"
                  class="pointer" @row-click="clickRow">
                <el-table-column prop="fileName" show-overflow-tooltip
                                 label="文件名称"></el-table-column>
              </el-table>
              <!--<el-pagination class="text_center m_t1"
                             @size-change="sizeChangeHandle"
                             @current-change="currentChangeHandle"
                             :current-page="pageNo"
                             :page-sizes="[10, 20, 50, 100]"
                             :page-size="pageSize"
                             :total="total"
                             :pager-count="3"
                             small
                             background
                             layout="total, prev, pager, next">
              </el-pagination>-->
            </el-col>
          </el-row>
        </div>
        <div class="flex_b_c">
          <div>
            <el-tag
                :key="tag"
                v-for="(tag, index) in dynamicTags" v-if="index < 3"
                :disable-transitions="false">
              {{ tag.labelName }}
            </el-tag>
            <el-popover v-if="dynamicTags.length > 3"
                        placement="right"
                        width="400"
                        trigger="click">
              <el-tag
                  :key="tag"
                  v-for="(tag, index) in dynamicTags" v-if="index > 2"
                  :disable-transitions="false">
                {{ tag.labelName }}
              </el-tag>
              <el-button slot="reference" size="small" class="moreBtn">...</el-button>
            </el-popover>
          </div>

          <div>
            <el-button
                v-if="fileTypeSuffix == 'pdf' && hasPermission('admin:resources:print') && dataList.length > 0"
                size="small" type="primary" plain icon="el-icon-printer"
                circle
                @click="print(info)"></el-button>

            <el-button
                v-if="hasPermission('admin:resources:download') && dataList.length"
                size="small" type="primary" plain icon="el-icon-download"
                circle
                @click="downloadThree(info)"></el-button>
          </div>
        </div>
      </el-col>

      <el-col :span="6" class="infoBox" v-show="showList">
        <el-form size="small" :model="inputForm" ref="inputForm" label-width="130px" disabled
                 v-if="listType == 1">
          <el-form-item label="事由/题名：" prop="fileName">
            <el-input v-model="inputForm.fileName"></el-input>
          </el-form-item>
          <el-form-item label="照片号/底片号：" prop="photoNumber">
            <el-input v-model="inputForm.photoNumber"></el-input>
          </el-form-item>
          <el-form-item label="时间：" prop="time">
            <el-input v-model="inputForm.time"></el-input>
          </el-form-item>
          <el-form-item label="年度：" prop="resourceYear">
            <el-input v-model="inputForm.resourceYear"></el-input>
          </el-form-item>
          <el-form-item label="地点：" prop="place">
            <el-input v-model="inputForm.place"></el-input>
          </el-form-item>
          <el-form-item label="人物：" prop="resourceCharacter">
            <el-input v-model="inputForm.resourceCharacter"></el-input>
          </el-form-item>
          <el-form-item label="背景：" prop="background">
            <el-input v-model="inputForm.background"></el-input>
          </el-form-item>
          <el-form-item label="拍摄者：" prop="photographer">
            <el-input v-model="inputForm.photographer"></el-input>
          </el-form-item>
          <el-form-item label="作者：" prop="author">
            <el-input v-model="inputForm.author"></el-input>
          </el-form-item>
          <el-form-item label="相册名：" prop="albumName">
            <el-input v-model="inputForm.albumName"></el-input>
          </el-form-item>
          <el-form-item label="主题词/关键词：" prop="keyword">
            <el-input v-model="inputForm.keyword"></el-input>
          </el-form-item>
          <el-form-item label="密级：" prop="classificationLevel">
            <el-input v-model="inputForm.classificationLevel"></el-input>
          </el-form-item>
          <el-form-item label="保管期限：" prop="storagePeriod">
            <el-input v-model="inputForm.storagePeriod"></el-input>
          </el-form-item>
          <el-form-item label="类型规格：" prop="typeSpecifications">
            <el-input v-model="inputForm.typeSpecifications"></el-input>
          </el-form-item>
          <el-form-item label="备注：" prop="remarks">
            <el-input v-model="inputForm.remarks"></el-input>
          </el-form-item>
        </el-form>
        <el-form size="small" :model="inputForm" ref="inputForm" label-width="100px" disabled v-else>
          <el-form-item label="文件名称：" prop="fileName">
            <el-input v-model="inputForm.fileName"></el-input>
          </el-form-item>
          <el-form-item label="文件大小：" prop="fileSizeFormat">
            <el-input v-model="inputForm.fileSizeFormat"></el-input>
          </el-form-item>
          <el-form-item label="文件格式：" prop="fileFormat">
            <el-input v-model="inputForm.fileFormat"></el-input>
          </el-form-item>
          <el-form-item label="文件描述：" prop="fileDesc">
            <el-input v-model="inputForm.fileDesc"></el-input>
          </el-form-item>
          <el-form-item label="配音" prop="photographed" v-if="listType == 2">
            <el-input v-model="inputForm.photographed"></el-input>
          </el-form-item>
          <el-form-item label="出镜" prop="impersonator" v-if="listType == 3">
            <el-input v-model="inputForm.impersonator"></el-input>
          </el-form-item>
          <el-form-item label="文案" prop="copywriting" v-if="listType == 2 || listType == 3">
            <el-input v-model="inputForm.copywriting"></el-input>
          </el-form-item>
          <el-form-item label="撰稿" prop="contribution" v-if="listType == 2 || listType == 3">
            <el-input v-model="inputForm.contribution"></el-input>
          </el-form-item>
          <el-form-item label="拍摄人：" prop="shotUser" v-if="listType == 3">
            <el-input v-model="inputForm.shotUser"></el-input>
          </el-form-item>
          <el-form-item label="拍摄日期：" prop="shotTime" v-if="listType == 3">
            <el-input v-model="inputForm.shotTime"></el-input>
          </el-form-item>
          <el-form-item label="拍摄任务：" prop="shotTask" v-if="listType == 3">
            <el-input v-model="inputForm.shotTask"></el-input>
          </el-form-item>
          <el-form-item label="拍摄地点：" prop="shotAddress" v-if="listType == 3">
            <el-input v-model="inputForm.shotAddress"></el-input>
          </el-form-item>
          <el-form-item label="拍摄设备：" prop="shotDevice" v-if="listType == 3">
            <el-input v-model="inputForm.shotDevice"></el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  name: "fileDetails",
  data(){
    return{
      visible:false,
      showList: false,
    }
  },

  methods:{
    init(){
      this.visible = true
    },

    handleClose(){
      this.visible=false;
    },
  }
}
</script>

<style scoped>

</style>
