<template>
  <index-list :pageType="pageType"></index-list>
</template>

<script>
import IndexList from "@/views/modules/dyyg/comprehensive/indexList.vue";

export default {
  name: "general",
  components: {IndexList},
  data(){
    return{
      pageType:'general',
    }
  }
}
</script>

<style scoped>

</style>