<template>
  <el-dialog :title="title" :modal-append-to-body="true" width="800px"
             :append-to-body="true" :close-on-click-modal="false" :visible.sync="visible"
             :before-close="handleClose">
    <div v-show="setp == 0">
      <div class="dialogHeight">
        <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick">
          <template class="custom-tree-node" slot-scope="{ node, data }">
            <div class="flex_l_c">
              <el-image fit="contain" :src="require('@/assets/img/folder.png')"
                        class="itemImg"></el-image>
              <div style="padding-left: 10px">{{ node.label }}</div>
            </div>
          </template>
        </el-tree>
      </div>
      <div class="flex_b_c">
        <el-button size="small" @click="addFolder()">新建文件夹</el-button>
        <div>
          <el-button type="primary" size="small" @click="nextStep()">确定</el-button>
          <el-button size="small" @click="handleClose()">关闭</el-button>
        </div>
      </div>
    </div>
    <div v-show="setp == 1">
      <div class="dialogHeight">
        <resume-upload ref="resumeUpload" :fetchUrl="fetchUrl" @upFileDate="upFileDate" :allowFiles="allowFiles"
                       :upFileSize="upFileSize" :dir="'resource'"
                       :hideUploadPlugin="true" :allowFilesShow="false"></resume-upload>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import ResumeUpload from "@/components/Upload/resumeUpload.vue";

export default {
  components: {ResumeUpload},
  name: "fileAdd",
  data() {
    return {
      title:'选择资源保存路径',
      visible: false,
      setp: 0,
      data: [
        {
          label: '全部文件',
          children: [
            {
              label: '一级 1',
              children: [{
                label: '二级 1-1',
                children: [{
                  label: '三级 1-1-1'
                }]
              }]
            }, {
              label: '一级 2',
              children: [{
                label: '二级 2-1',
                children: [{
                  label: '三级 2-1-1'
                }]
              }, {
                label: '二级 2-2',
                children: [{
                  label: '三级 2-2-1'
                }]
              }]
            }, {
              label: '一级 3',
              children: [{
                label: '二级 3-1',
                children: [{
                  label: '三级 3-1-1'
                }]
              }, {
                label: '二级 3-2',
                children: [{
                  label: '三级 3-2-1'
                }]
              }]
            }
          ],
        },
      ],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      fileType:'',
      selectFolder: '',
      fetchUrl: '',
      allowFiles: [],
      upFileSize: 2048,
    }
  },

  methods: {
    init(fileType) {
      this.fileType = fileType
      this.visible = true
    },

    //新建文件夹
    addFolder() {

    },

    //选择文件夹
    handleNodeClick(data) {
      this.selectFolder = data.id
    },

    //确定
    nextStep() {
      if (this.selectFolder === '') {
        this.$message.warning('请选择要上传文件的文件夹')
      } else {
        this.title = '正在上传文件'
        this.setp++
        this.selectFile()
      }
    },

    selectFile() {
      this.fetchUrl = ''
      if (this.fileType == '0') {
        this.fetchUrl = 'zyd-common/file/uploadThreeFile'
      }
      this.allowFiles = this.getResourceUploadFileFormat(this.fileType)
      this.$nextTick(() => {
        this.$refs.resumeUpload.$refs.upFile.click()
      })
    },

    //保存上传文件
    upFileDate(data) {
      let row = data.map((item, index) => {
        if (item.upDate) {
          this.$set(item.upDate, 'fileID', item.id)
          this.$set(item.upDate, 'fileBrowse', item.id)
          this.$set(item.upDate, 'fileSort', index + 1)
          this.$set(item.upDate, 'masterView', 1)
          this.$set(item.upDate, 'fileSize', item.origSize)
          return item.upDate
        } else {
          return item
        }
      })
      let files = []
      if (this.fileType != 0) {
        files = [
          {
            fileFormat: row[0].fileFormat,
            fileName: row[0].fileName,
            fileSize: row[0].fileSize,
            fileSizeFormat: row[0].fileSizeFormat,
            url: row[0].filePath,
            type: this.fileType,
          }
        ]
      } else {
        row[0].fileList.forEach(item => {
          files.push(
              {
                fileFormat: item.fileFormat,
                fileName: item.fileName,
                fileSize: item.fileSize,
                fileSizeFormat: item.fileSizeFormat,
                url: item.filePath,
                type: this.fileType,
              }
          )
        })
      }
      this.$axios(this.api.digital.comprehensiveResourcesFileLinkSave, {
        files,
        folderFlag: 1,
        folderName: this.fileType == 0 ? row[0].compressName.split('.')[0] : '',
        parentId: this.parentId,
        resourceId: this.resourceId,
      }, 'post').then((res) => {
        if (res.status) {
          // this.getDataList(1)
        } else {
          this.$message.error(res.msg)
        }
      })
      this.$refs.resumeUpload.deleteFile(row[0].fileID, '', 1)
    },

    handleClose() {
      this.setp = 0
      this.title = '选择资源保存路径'
      this.selectFolder = ''
      this.visible = false;
    },
  },
}
</script>

<style scoped>
.dialogHeight {
  height: 400px;
  overflow-y: auto;
}

.itemImg {
  height: 20px;
}

.upTishiao {
  margin-top: 10px;
  font-size: 10px;

  span {
    font-weight: bold;
  }
}
</style>