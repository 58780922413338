<template>
  <el-dialog title="选择资源保存路径" :modal-append-to-body="true" width="800px"
             :append-to-body="true" :close-on-click-modal="false" :visible.sync="visible"
             :before-close="handleClose">
    <div class="dialogHeight">
      <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick">
        <template class="custom-tree-node" slot-scope="{ node, data }">
          <div class="flex_l_c">
            <el-image fit="contain" :src="require('@/assets/img/folder.png')"
                      class="itemImg"></el-image>
            <div style="padding-left: 10px">{{ node.label }}</div>
          </div>
        </template>
      </el-tree>
    </div>
    <div class="flex_b_c">
      <el-button size="small" @click="addFolder()">新建文件夹</el-button>
      <div>
        <el-button type="primary" size="small" @click="sureMove()" v-no-more-click>确定</el-button>
        <el-button size="small" @click="handleClose()">关闭</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "fileMove",
  data(){
    return{
      visible:false,
      data: [
        {
          label: '全部文件',
          children: [
            {
              label: '一级 1',
              children: [{
                label: '二级 1-1',
                children: [{
                  label: '三级 1-1-1'
                }]
              }]
            }, {
              label: '一级 2',
              children: [{
                label: '二级 2-1',
                children: [{
                  label: '三级 2-1-1'
                }]
              }, {
                label: '二级 2-2',
                children: [{
                  label: '三级 2-2-1'
                }]
              }]
            }, {
              label: '一级 3',
              children: [{
                label: '二级 3-1',
                children: [{
                  label: '三级 3-1-1'
                }]
              }, {
                label: '二级 3-2',
                children: [{
                  label: '三级 3-2-1'
                }]
              }]
            }
          ],
        },
      ],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      selectFolder:'',
      moveIds:[],
    }
  },

  methods:{
    init(ids){
      this.moveIds = ids
      this.visible = true
    },

    //选择文件夹
    handleNodeClick(data) {
      this.selectFolder = data.id
    },

    //新建文件夹
    addFolder() {

    },

    //确认移动
    sureMove(){

    },

    handleClose(){
      this.visible = false
    },
  },
}
</script>

<style scoped>
.dialogHeight {
  height: 400px;
  overflow-y: auto;
}

.itemImg {
  height: 20px;
}
</style>